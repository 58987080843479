<script setup>
import { ref } from 'vue';
import { useLayoutStore } from '~/stores/layout.store';
import { useMetaStore } from '~/stores/meta.store';

const { locale } = useI18n();
const languages = ref([]);
const currentLanguage = ref('');
const layoutStore = useLayoutStore();
const metaStore = useMetaStore();

currentLanguage.value = layoutStore.languages.find((language) => {
	return language.code.toLowerCase() === locale.value.toLowerCase();
})

languages.value = layoutStore.languages.map((language) => {
	return {
		code: language.code,
		name: language.name,
		url: metaStore.meta?.href_langs?.[language.code],
	}
}).filter((language) => {
	return !!language.url;
});
</script>
<template>
	<w-select-menu
		v-model="currentLanguage"
		:options="languages"
		optionAttribute="name"
		>
		<template #option="{ option: language }" >
			<a class="w-full block px-4 py-2" :href="language.url" v-if="currentLanguage.name!=language.name">
				{{ language.name ?? language.code }}
			</a>
		</template>
	</w-select-menu>
</template>